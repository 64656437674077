import { Component, OnInit, OnDestroy, AfterContentInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { CmsserviceService } from '../../../common-cms-service/cmsservice.service';
import { CommonAccountVerifyService } from '../../../common-myaccount-service/common-account-verify.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterContentInit {
  title = '';
  pagetitle: string;
  categoryListing: any;
  pageslug: string;
  siteSetting: any;
  categories = [];
  catDetail = [];
  slug: string;
  ngUnsubscribe = new Subject<void>();
  isLoggedInUser: boolean;
  testPopup = true;
  poptitle: string;
  frontBaseURL = environment.frontBaseURL;
  username: any;
  lastname: any;
  cookieValue: string;
  constructor(
    private cmsService: CmsserviceService,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private logoutService: CommonAccountVerifyService,
    private cookiesService: CookieService

  ) {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        window.scroll(0, 0);
        const slugArr = val.url.split('/');
        const allow = ['contact-us', 'donation', 'support', 'request-for-franchise'];
        if (allow.includes(slugArr[slugArr.length - 1])) {
          this.testPopup = false;
        } else {
          this.testPopup = true;
        }
      }
    });
  }
  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true);
    this.CategoryList();
    this.userIdentifyMyaccount();
    this.getTestCategoryList();
    this.cmsService.commonSiteSetting();
    this.cmsService.commonSiteSettingSingleton().subscribe(res => {
      this.siteSetting = res;
      $('body').find('.header-basket-count').html(res.user_basket_count);
    });

  }

  /** comment
   * to get categories
   */
  CategoryList() {
    this.cmsService.getFaqCategoryList()
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        this.categories = res.data;

      });
  }
  scroll = (): void => {
    if (window.scrollY > 200) {
      $('.header-fixed').addClass('darkHeader');
      $('.logo').attr('src', 'assets/images/logo.png');
    } else {
      $('.header-fixed').removeClass('darkHeader');
      $('.logo').attr('src', 'assets/images/logo-w.png');
    }
  };
  userLogouts() {
    this.logoutService.logout();
    this.isLoggedInUser = false;
    this.ngOnInit();
    this.router.navigate(['/']);
  }

  /** comment
   * get test category
   */
  getTestCategoryList() {
    this.cmsService.getTestCategories()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.categoryListing = res.data;
      });
  }

  /** comment
   * get user login record
   */
  userIdentifyMyaccount() {
    this.isLoggedInUser = false;
    const username = localStorage.getItem('userfname');
    if (username) {
      if (username.length > 12) {
        this.username = username.substring(0, 12) + '.';
      } else {
        this.username = username;
      }
    }
    this.cookieValue = this.cookiesService.get('userLogin');
    this.lastname = localStorage.getItem('userlname');
    const isLogginTrue = localStorage.getItem('isUserLoggedIn');
    const usertoken = localStorage.getItem('usertoken');
    if ((this.username) && (isLogginTrue) && (usertoken) && (this.cookieValue)) {
      this.isLoggedInUser = true;
    } else {
      this.isLoggedInUser = false;
    }
  }

  /** comment
   * allow to type only number
   */
  checkMobileNumber() {
    $('.uphone').keydown(function (e) {
      if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
        (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
        (e.keyCode >= 35 && e.keyCode <= 40)) {
        return;
      }
      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
      }
    });
  }

  ngAfterContentInit(): void {

    $(document).ready(function () {
      $('body').prepend('<div class="load1"></div>');
      $('body').prepend('<div class="load2"></div>');
      if ($(window).width() < 900) {
        $('nav ul li').on('click', function () {
          $(this).find('.submenu').slideToggle(300);
        });
      }
      if ($(window).width() < 900) {
        $('nav ul li a.links').on('click', function () {
          $('nav').removeClass('open');
          $('body,html').animate({
            scrollTop: 0
          }, 10);
        });
        $('.mega-menu li *').on('click', function () {
          $('nav').removeClass('open');
          $('body,html').animate({
            scrollTop: 0
          }, 10);
        });
      }
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scroll, true);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
