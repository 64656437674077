<!-- <div id="preloader">
   <div id="spinner">
      <img src="assets/images/logo.png" class="loader-logo">
      <div class="preloader-dot-loading">
         <div class="cssload-loading"><i></i><i></i><i></i><i></i></div>
      </div>
   </div>
</div> -->
<div class="get-in-touch transition" (click)="checkMobileNumber()" *ngIf="testPopup" data-toggle="modal" data-target="#autoload">get in touch</div>
<div class="header-fixed transition">
   <!--top strip start here-->
   <div class="top-strip">
      <div class="col-md-11">
         <ul class="list list-inline">
            <li><a [routerLink]="['/become-a-volunteer']" class="transition">Become A Volunteer </a></li>
            <li><a [routerLink]="['/donation']" class="transition">Make A Donation </a></li>
         </ul>
      </div>
      <div class="col-md-1">
         <ul class="list list-inline footer-socials">
            <li><a href="{{siteSetting?.social_telegram_url}}" target="_blank" class="fb btn-hover"><i class="fa fa-paper-plane" aria-hidden="true"></i></a>
            </li>
            <li><a href="{{siteSetting?.social_facebook_url}}" target="_blank" class="fb btn-hover"><i class="fa fa-facebook" aria-hidden="true"></i></a>
            </li>
            <li><a href="{{siteSetting?.social_twitter_url}}" target="_blank" class="tw btn-hover"><i class="fa fa-twitter" aria-hidden="true"></i></a>
            </li>
            <li><a href="{{siteSetting?.social_linkedin_url}}" target="_blank" class="linkdin btn-hover"><i class="fa fa-linkedin"
                     aria-hidden="true"></i></a></li>
         </ul>
      </div>
   </div>
   <!--top strip ends here-->
   <!--header start here-->
   <header>
      <div class="container-fluid">
         <div class="col-md-3">
            <a [routerLink]="['/']"><img src="assets/images/logo-w.png" class="logo transition" /></a>
         </div>
         <div class="col-md-7 static padding0">
            <div class="nav-icon1 open-nav"><span></span><span></span><span></span></div>
            <nav class="transition static">
               <div class="nav-icon1 open close-nav"><span></span><span></span><span></span></div>
               <ul class="static">
                  <li><a class="links" [routerLink]="['/']">Home</a></li>
                  <li class="sub-menu-wrap">
                     <a class="cursor">Sehyog <i class="fa fa-angle-down" aria-hidden="true"></i> </a>
                     <ul class="submenu submenu-sml">
                        <li><a class="links" [routerLink]="['/sehyog/inception-story']">Inception Story</a></li>
                        <li><a class="links" [routerLink]="['/sehyog/vision-mission']">Vision & Mission</a></li>
                        <li><a class="links" [routerLink]="['/sehyog/team']">Team</a></li>
                        <li><a class="links" [routerLink]="['/sehyog/photo-gallery']">Photo Gallery</a></li>
                        <li><a class="links" [routerLink]="['/sehyog/video-gallery']">Video Gallery</a></li>
                     </ul>
                  </li>
                  <!-- 11+ mock test menu testing added on 08-04-24 -->
                  <li class="sub-menu-wrap">
                     <a class="cursor">11+ Mock Test <i class="fa fa-angle-down" aria-hidden="true"></i> </a>
                     <ul class="submenu submenu-sml">
                        <li><a class="links" [routerLink]="['/11plus-help-club/category/11-mock-test']" [queryParams]="{location: 'Ilford'}">Ilford</a></li>
                        <li><a class="links" [routerLink]="['/11plus-help-club/category/11-mock-test']" [queryParams]="{location: 'Chelmsford'}">Chelmsford</a></li>
                        <li><a class="links" [routerLink]="['/11plus-help-club/category/11-mock-test']" [queryParams]="{location: 'Southend-on-sea'}">Southend-on-sea</a></li>
                        <li><a class="links" [routerLink]="['/11plus-help-club/category/11-mock-test']" [queryParams]="{location: 'Basildon'}">Basildon</a></li>
                        <li><a class="links" [routerLink]="['/11plus-help-club/category/11-mock-test']" [queryParams]="{location: 'other'}">Other Centers & Online</a></li>
                     </ul>
                  </li>
                  <!-- end of newly added menu -->
                  <li class="mega-menu-wrap tab">
                     <a class="cursor" style="text-transform: capitalize">11plusHELPclub <i class="fa fa-angle-down" aria-hidden="true"></i></a>
                     <ul class="submenu mega-menu">
                        <li class="col col1">
                           <h3>11plusHELPclub</h3>
                           <div class="text-sml text-justify" [innerHTML]="(siteSetting?.eleven_plus).substr(0,310)">
                           </div>
                           <div class="clearfix"></div>
                           <br />
                           <a [routerLink]="['/11plus-help-club']" class="a-link links">READ MORE +</a>
                        </li>
                        <li class="col col2" style="position: relative;">
                           <h3>Category </h3>
                           <div class="clearfix"></div>
                           <ol>
                                 <li *ngFor="let category of categoryListing"><a
                                   [routerLink]="['/11plus-help-club/category',category.category_slug]" class="links"><i
                                       class="fa fa-caret-right" aria-hidden="true"></i> {{category.category_name}}</a>
                              </li>
                           </ol>
                           <div class="clearfix"></div>
                           <br />
                           <a [routerLink]="['/11plus-help-club/previous-test']" class="a-link links prev-test">Previous Sessions +</a>
                        </li>
                        <li class="col col3">
                           <h3>FAQ </h3>
                           <div class="clearfix"></div>
                           <ol>
                              <li *ngFor="let category of categories;  let i= index;"><a class="links remove-mega"
                                    [routerLink]="['/11plus-help-club/faq',category.slug]"><i class="fa fa-caret-right"
                                       aria-hidden="true"></i>{{category.faq_category}}</a></li>
                              <li><a [routerLink]="['/support']"  class="links"><i class="fa fa-caret-right" aria-hidden="true"></i>
                                    Support Form</a></li>
                           </ol>
                        </li>
                        <li class="col col4">
                           <h3>Get Inspired </h3>
                           <div class="clearfix"></div>
                           <ol>
                              <li><a [routerLink]="['/11plus-help-club/get-inspired/success-stories']"  class="links"><i
                                       class="fa fa-caret-right" aria-hidden="true"></i> Success Stories</a></li>
                              <li><a [routerLink]="['/11plus-help-club/get-inspired/testimonial']" class="links"><i
                                       class="fa fa-caret-right" aria-hidden="true"></i> Testimonial</a></li>
                           </ol>
                        </li>
                        <li class="col col5">
                           <a [routerLink]="['/request-for-franchise']" class="btn btn-custom border btn-hover links"><img
                                 src="assets/images/handshake.svg" /> &nbsp;Request For Franchise <i
                                 class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                           <div class="clearfix"></div>
                           <!-- <br />
                           <a href="{{siteSetting?.social_telegram_url}}" target="_blank" class="links btn btn-custom border btn-hover teligram-icon"><img
                                 src="assets/images/telegram-logo.svg" /> &nbsp; Telegram Group <i
                                 class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                           <div class="clearfix"></div> -->
                           <!-- <br />
                           <a href="http://onlineexam.sehyog.co.uk/ecas/userlogin.aspx?clientid=239300" target="_blank" class="links btn btn-custom border btn-hover teligram-icon"><img
                                 src="assets/images/online-exam-1.svg" /> &nbsp; Online Exam <i
                                 class="fa fa-long-arrow-right" aria-hidden="true"></i></a> -->
                        </li>
                        <img src="assets/images/mega-menu.png" />
                     </ul>
                  </li>
                  <li><a class="links" [routerLink]="['/11plus-live']">11PLUS INFO</a></li>
                  <li><a class="links" [routerLink]="['/academy']">Academy</a></li>
                  <li><a [routerLink]="['/contact-us']"  class="links">Contact us</a></li>
                  <li *ngIf="isLoggedInUser"><a [routerLink]="['/basket']"  class="links"><i class="fa fa-shopping-basket" aria-hidden="true"></i> Basket ( <span class="header-basket-count">0</span> )</a></li>
                  <!-- <li *ngIf="isLoggedInUser"><a [routerLink]="['/dashboard']"  class="links"><i class="fa fa-id-badge" aria-hidden="true"></i> My Dashboard </a></li> -->
               </ul>
               <div class="clearfix"></div>
               <br />


               <ul class="list list-inline login-section" *ngIf="!isLoggedInUser">
                  <li><a [routerLink]="['/signin']" class="btn btn-custom border btn-hover">Login <i
                           class="fa fa-long-arrow-right" aria-hidden="true"></i></a></li>
                  <li><a [routerLink]="['/signup']" class="btn btn-custom org btn-hover">Sign up <i
                           class="fa fa-long-arrow-right" aria-hidden="true"></i></a></li>
               </ul>
            </nav>
         </div>

         <div class="col-md-2 padding0">
            <ul class="my-acc-drop" data-login="has-login" *ngIf="isLoggedInUser; else templateName">
               <li class="dropdown ">
                  <a class="cursor dropdown-toggle" data-toggle="dropdown" aria-hidden="true">
                     <span class="img"><i class="fa fa-user-circle-o" aria-hidden="true"></i> </span> <label
                        class="res-hide">{{username  | titlecase  }} <i class="fa fa-angle-down" aria-hidden="true"></i></label>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-right ">
                     <li><a [routerLink]="['/dashboard']" class="cursor">My Account</a></li>
                     <li><a [routerLink]="['/dashboard/preferences']" class="cursor"> Preferences</a></li>
                     <li><a (click)="userLogouts()">Logout</a></li>
                  </ul>
               </li>
            </ul>



            <ng-template #templateName>
               <ul class="list list-inline ">
                  <li><a [routerLink]="['/signin']" class="btn btn-custom white btn-hover">Login</a></li>
                  <li><a [routerLink]="['/signup']" class="btn btn-custom org btn-hover">Sign up</a></li>
               </ul>

            </ng-template>

         </div>
      </div>
   </header>
   <!--header ends here-->
</div>